// from
// import styles from '../OrderImageForm.module.scss';

.delimeter {
  width: 100%;
  margin: 8px 0;
  height: 1px;
  background-color: rgba(128, 128, 128, 0.4);
}

// TODO - duplicated
// .itemHeadActions {
//   display: flex;
//   // align-items: center;
//   align-items: flex-end;
// }
.itemHeadActionButton {
  width: 32px; // 28px
  height: 32px; // 28px
  padding: 0;
}
// .cartButton {
//   &:hover,
//   &:global(.active) {
//     color: #5dd670;
//   }
// }
.removeButton {
  &:hover {
    color: #ff5f5f;
  }
}

.itemContainer {
  margin-bottom: 16px; // 1rem
  margin-right: 10px;
}

.marginBottom {
  // padding-top: 20px;
  margin-bottom: 16px; // 1rem
}
