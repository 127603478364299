// .radio_button__container
// <label>
.root {
  // position: relative;
  display: flex;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;

  &:hover .checkMark {
    background-color: var(--main-check-box-mark-color);
  }

  align-items: center;
  &.multilineLabel {
    align-items: flex-start;
  }

  &.disabled {
    color: grey;
    cursor: not-allowed;
  }
  &.disabled .checkMark {
    border-color: rgba(128, 128, 128, 0.4); // grey;
  }
}

// .radio_button__input_check_mark
.inputRadio {
  position: absolute;
  // visibility: hidden;
  opacity: 0;
}

// .radioButtonCheckMarkContainer, .radioButtonCheckMark
.checkMark {
  // position: absolute;
  // top: 0;
  // left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: var(--main-check-box-mark-color) 2px solid; // #282c34
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkMarkChecked {
  display: block;
  background-color: var(--main-secondary-color); // #000000;
  // top: 3px;
  // left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

// .radio_button__label
.labelBlock {
  margin-left: 10px; // 8px;
  font-size: 0.875rem; // 14px
}
