.root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // display: inline-block;

  border: none;
  cursor: default;
  // box-sizing: border-box;
  // height: 32px;
  line-height: 24px;
  outline: 0;
  padding: 0;
  margin: 4px;
  font-size: 0.8125rem;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  white-space: nowrap;
  border-radius: 12px;
  // vertical-align: middle;
  text-decoration: none;
  user-select: none;
  color: rgba(0, 0, 0, 0.87);
  background-color: var(--main-check-box-mark-color); // #e0e0e0;

  // TODO - optional param
  max-width: 150px;

  &:hover {
    background-color: rgb(206, 206, 206); // !important;
  }
}

.clickable {
  cursor: pointer;
}

.innerLabel {
  padding-left: 12px;
  padding-right: 12px;
  // white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.delete {
  margin: 0 5px 0 -6px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.26);

  & > svg {
    // vertical-align: middle;
    width: 10px;
    height: 10px;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.4);
  }
}
