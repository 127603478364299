.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

// .label { ???
.label {
  // font-family: TT Hoves Regular, sans-serif;
  font-family: var(--main-regular-font);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  padding-left: 10px;
  padding-top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
}
// label__right
.labelRight {
  padding-left: 0;
}

.itemLabelContainer {
  position: relative;
  cursor: pointer;
}
.itemLabel {
  display: inline-block;
  max-width: 260px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: initial;
  white-space: nowrap; // for chrome!
  color: #5dbdf9;
}
// copy icon btn
// TODO - show on text hover

.itemHeadActions {
  display: flex;
  // align-items: center;
  align-items: flex-end;
}
.itemHeadActionButton {
  width: 32px; // 28px
  height: 32px; // 28px
  padding: 0;
}
.cartButton {
  &:hover,
  &:global(.active) {
    color: #5dd670;
  }
}
.removeButton {
  &:hover {
    color: #ff5f5f;
  }
}
