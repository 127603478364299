
  .iconSize {
    width: 20px;
    height: 20px;
  }
  .openAll {
    padding-top: 20px;
    cursor: pointer;
    color: #5dbdf9;
    &:hover {
      color: #58a8e1;
    }
    &:active {
      color: #539ed2;
    }
  }

  .spanClick {
    padding-top: 20px;
    cursor: pointer;
    color: #5dbdf9;
    &:hover {
      color: #58a8e1;
    }
    &:active {
      color: #539ed2;
    }
  }
