
  .contentRoot {
    padding: 10px;
  }
  .list {
    list-style: circle;
    margin: 0;
    padding-left: 16px;
  }
  .listItem {
    cursor: default;

    &:hover {
      color: var(--main-secondary-color);
    }
  }
  .listItemActive {
    list-style: disc;
  }
  .selected {
    cursor: pointer;
    color: var(--main-secondary-color);
  }
