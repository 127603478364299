/* .root {
  // width: 383px;
  // margin-top: 20px;
  // pointer-events: none;
} */

.dataContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  pointer-events: all;
  border-radius: 3px;

  margin-bottom: 20px;
}

.dataHeader {
  font-size: 19px;
  color: #333333;
}

.formControl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}

.labelContainer {
  margin-bottom: 5px;
}
.label {
  font-size: 12px;
  color: #000000;
  opacity: 0.5;
}

.credentialsContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  pointer-events: all;
  border-radius: 3px;
  margin-bottom: 20px;
  max-width: 343px;
  max-height: 420px;
}
.labelCredentials {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.closeButton {
  float: right;
}

.credentials {
  font-size: 12px;
  color: #000000;
}

.token {
  font-size: 12px;
  color: #000000;
  text-overflow: clip;
  white-space: pre-wrap;
  overflow-y: hidden;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.actionsContainer {
  pointer-events: all;
}
