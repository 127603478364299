
  .mapDrawControls {
    position: absolute;
    z-index: 10;
    /* left: 410px; */
    left: 460px;
    /* top: 80px; */
    top: 61px;
  }

  // .layers_settings_group
  .mapSettingsGroup {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 20px 36px 0;

    display: flex;
    align-items: flex-end;

    & > div {
      margin-left: 5px;
    }
  }

  /* .mapMainGroup {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px 20px 0 0;

    display: flex;
    align-items: flex-start;
  } */
  // tmp class - второй ряд меню (авторизованный пользователь)
  /* .mapMainGroupTest {
    position: absolute;
    top: 0;
    right: 0;
    margin: 80px 20px 0 0;

    display: flex;
    align-items: flex-end;
  } */

  // deprecated
  /* .mapCopyrightSection {
    position: absolute;
    bottom: 0;
    right: 0;
    // margin-right: 20px;
    // margin: 0 auto;
    width: 100vw;
    display: flex;
    justify-content: center;
  } */

  .marginLeft {
    margin-left: 5px;
  }
