.panelContainer {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 250px;
  height: 46px;
  // right: 10%;
  right: 5px;
  bottom: 12px;
  transform: translate(-50%, -50%);
  // margin: 0 auto;

  background-color: #fff;
  border-radius: 3px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.dateValue {
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5dbdf9;
  font-size: large;
  font-weight: bold;
}

.dateDivider {
  padding: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #5dbdf9;
  font-size: large;
  font-weight: bold;
}

.link {
  // padding: 10px;
  cursor: pointer;
}

.tooltip {
  width: fit-content;
}
