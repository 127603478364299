.root {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: #14306a;
}

.statusBarInfo {
  display: inline-block;
  min-width: 50%;
  // color: #5dbdf9;
  color: #fff;
}

.statusBarInfoValue {
  margin: 0 3px 0 3px;
}

.statusBarButton {
  height: 28px;
  margin: 5px 10px 5px 5px;
}
