.root {
  position: absolute;
  top: 10px; // 60px; - с search panel
  left: 20px;
  z-index: 10;
  // display: flex;
  // flex-direction: column;
  pointer-events: none;
  // min-width: 420px;
}

.backButton {
  height: 45px;
  border-radius: 0;
}

// root
.tabsContainer {
  background-color: #14306a;
  border-radius: 3px;
  height: 45px;
  // width: 650px;
  // box-sizing: border-box;
  pointer-events: all;
  display: inline-flex; // по ширине контента
  min-width: 420px;
  // align-items: center;
}

.tabContainer {
  margin: 0 24px;
  cursor: pointer;
  color: #5dbdf9;
  height: 43px;
  display: flex;
  align-items: center;
}

.tabContainerActive {
  margin: 0 24px;
  cursor: pointer;
  color: #5dbdf9;
  height: 43px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #5dbdf9;
}

.contentContainer {
  display: table; // по ширине контента
  // pointer-events: all; // будем управлять детальнее на уровне ниже
  margin-top: 20px;
}
