
  .root {
    position: absolute;
    top: 0;
    // background-color: gray;
    // opacity: 0.5;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background-color: rgba(128, 128, 128, 0.2);
  }

  .content {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 20px 40px;
    border-radius: 8px;
    color: #373737;
  }
