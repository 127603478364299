// root container
.root {
  display: flex;
  flex-direction: column;
  min-width: 360px;
  max-width: 370px;
  background-color: #ffffff;
  padding: 20px;
  // padding-bottom: 0;
  border-radius: 3px;
  position: absolute;
  box-sizing: border-box;
  left: 430px;
  // bottom: 8px; // если прижимать к низу
}

.header {
  // h3
  // font-size: 1.25rem; // 20px
  font-size: 1.125rem; // 18px
  text-transform: uppercase;
}

// .content {
//   //
// }

.paramTitle {
  margin: 4px 0 10px;
  font-size: 0.875rem; // 14px
}

// .paramField {
//   margin-bottom: 12px;
// }

// buttons, actions
.actionsContainer {
  display: flex;
  justify-content: space-around;
}

.marginBottom {
  // padding-top: 20px;
  margin-bottom: 16px; // 1rem
}
