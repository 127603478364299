
  .root {
    // height: 100%;
    top: 61px; // 0;

    // display: flex;
    flex-direction: column;
    justify-content: space-around;
    pointer-events: all;
    width: 18vw;
    // min-width: 420px; // 314px; 440px;
    min-width: 430px; // добавил 10px для custom scroll
    position: absolute;
    // top: 0;
    left: 20px;
    z-index: 10;
    // overflow-y: auto;

    // collapse panel
    // thx maps.yandex.ru (позаимствовал)
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
      -webkit-transform 0.2s ease-in-out;
    transform: translateX(-430px);
    opacity: 0;
    visibility: hidden;
  }
  :global(._show) {
    transform: none;
    opacity: 1;
    visibility: visible;
  }

  .helloText {
    background: #fff;
    padding: 16px 20px;
    margin-right: 10px;
    border-radius: 3px;
  }
  .helloTextLink {
    color: #367da8; // #217cb4; // var(--text-accent-color);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .scrollable {
    //overflow-y: auto;
    // max-height: calc(100vh - 136px); // было
    // стало
    // 136 --> 136 + 8 (padgin bottom); также не забыть поменять в пропсах CustomScrollBarContainer
    max-height: calc(100vh - 144px);
    margin-bottom: 8px;
  }

  .span_header_test {
    // font-family: TT Hoves Regular sans-serif;
    // font-family: var(--main-demibold-font);
    font-size: 19px;
    color: #ffffff;
  }

  // .span_checkbox {
  //   // font-family: TT Hoves Regular sans-serif;
  //   font-size: 14px;
  //   color: #ffffff;
  //   display: flex;
  //   align-items: center;
  //   margin-bottom: 12px;
  // }

  .div_search_option {
    background-color: #14306a;
    border-radius: 3px;
    // width: 100%;
    padding: 20px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin-bottom: 8px;
    margin-right: 10px; /* для suctom scroll, + убрал "width: 100%;" */
    &:last-child {
      margin-bottom: 0;
    }
  }

  .date_input_content {
    // display: flex;
    // justify-content: space-between;
    padding-top: 20px;
  }

  .div_search_option__search {
    background-color: #ffffff;
    border-radius: 3px;
    // width: 100%;
    // padding: 14px 18px;
    padding: 12px 18px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;

    margin-bottom: 8px;
    // margin-right: 10px; /* для suctom scroll на странице с параметрами!, + убрал "width: 100%;" */
    margin-right: 10px; // fix width
  }

  // ?
  .marginTop {
    margin-top: 8px;
  }
  .marginBottom {
    margin-bottom: 8px;
  }

  .searchPanelMask {
    height: calc(100% - 8px);
    width: 420px;
    position: absolute;
    background-color: #9395a86b;
    z-index: 10;
    // border-radius: 4px;
    border-radius: 3px;
  }
