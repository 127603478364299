
  .searchRoot {
    // top: -10px;
    // right: 8px;
  }

  .searchInput {
    color: black;
    border-radius: 4px;
    outline: none;

    border: 1px solid #e0e0e0;

    &:focus {
      border: 2px solid #a7b7e6;
    }

    // &::placeholder {
    //   color: black;
    // }
  }

  div.chipTag {
    margin-right: 0;
  }
