.root {
  width: 370px;
}

.inputLabel {
  cursor: pointer;
  padding-left: 4px;
}

.checkboxLabel {
  cursor: pointer;
  padding-left: 4px;
}

.checkbox {
  cursor: pointer;
}

.showTitlesCheckbox {
  cursor: pointer;
  margin-left: 4px;
}

.showTitlesCheckboxLabel {
  cursor: pointer;
  padding-left: 4px;
}

.inputRadioLabel {
  @extend .inputLabel;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
