.mapActionButton {
  width: 32px;
  height: 32px;
  padding: 0;

  margin-top: 4px;
  margin-left: 4px;

  &:global(.active) {
    color: #14306a;
    background-color: #ceeafb;

    &:hover {
      background-color: #f1f1f1;
    }
  }
}

.listItemFooter {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
}
.test11 {
  display: inline-block;
  vertical-align: middle;
}

.horizontalLine2 {
  border-bottom: 1px solid #e7e7e7;
  margin: 0 16px;
}
