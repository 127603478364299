.root {
  margin-right: 10px;
}

.productPreview {
  width: 24px;
  height: 24px;
  // margin-right: 10px;
  margin-left: 4px;
  color: var(--icon-primary-color); // #2954ff;
  background-color: #f0f6ff;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;

  &:hover {
    background-color: #d7e4f7; // #e9f2ff;
  }

  svg {
    vertical-align: middle;
  }
}
