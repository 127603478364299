
  .request_table__table_row_content {
    font-size: 12px;
    cursor: pointer;
  }

  .my_requests__table_header__span {
    font-size: 12px;
    color: #8997b4;
  }

  .my_request_span {
    font-size: 12px;

    .test {
      &:hover {
        background-color: #000066;
      }
    }
  }

  .pointerCursor {
    cursor: pointer;
  }
