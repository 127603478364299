.root {
  background-color: white;
  // padding: 28px 20px; 20px 16px
  margin-bottom: 8px;
  margin-right: 10px; // fix width

  // max-height: calc(100vh - 300px);
  // overflow-y: auto;

  transition: max-height 0.4s linear;
  overflow: hidden;
}

.listHeader {
  height: 43px;
  padding: 0 16px; // 0 20px;
  line-height: 40px;
  background-color: var(--main-primary-color);
  color: white; // var(--text-secondary-color);

  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  margin-right: 10px; // fix width

  display: flex;
  justify-content: space-between;
  align-items: center;
}

// test icon-button rotate
// transform: rotate(180deg);
// rotate: 180deg;
.closeButton {
  width: 36px;
  height: 36px;
  svg {
    transition: transform ease 0.4s;
    // rotate: 180deg; - work only FF
    transform: rotate(180deg);
  }
}
.closeButtonRotateTest {
  svg {
    // rotate: 0deg; - work only FF
    transform: rotate(0deg);
  }
}

.emptyResultMessage {
  text-align: center;
  padding: 40px 0;
}

// .list {}

.listItem {
  // background-color: white;
  // margin-bottom: 10px;
  padding: 14px 16px 10px; // 12px 16px;

  user-select: none;
  // cursor: pointer;

  /* &:hover {
    // background-color: lightblue; // lightgray
    // cursor: default;
  } */
}
.listItemSelected {
  background-color: rgba(167, 188, 253, 0.2);
}

.itemLabelContainer {
  position: relative;
  cursor: pointer;
}
.itemLabel {
  display: inline-block;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: initial;
  white-space: nowrap; // for chrome!
}
// copy icon btn
// TODO - show on text hover
.itemLabelSubCopyId {
  position: absolute;
  top: -8px;
  right: -16px; // можно без этого свойства
  // margin-left: 4px;
  line-height: 0;
  cursor: pointer;
  // word-break: initial;

  &:hover {
    background-color: rgba(184, 184, 184, 0.2);
    border-radius: 3px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
.itemHeadActions {
  display: flex;
}
.itemHeadActionButton {
  width: 32px; // 28px
  height: 32px; // 28px
  padding: 0;
}
.cartButton {
  &:hover,
  &:global(.active) {
    color: #5dd670;
  }
}
.removeButton {
  &:hover {
    color: #ff5f5f;
  }
}
//
// .fullImageButton {
.mapActionButton {
  // from .itemHeadActionButton
  width: 32px; // 28px
  height: 32px; // 28px
  padding: 0;

  margin-top: 4px;
  margin-left: 4px;

  &:global(.active) {
    // color: rebeccapurple;
    color: #14306a;
    background-color: #ceeafb;

    &:hover {
      background-color: #f1f1f1;
    }
  }
}

.imgContainer {
  // width: 85px;
  width: 82px;
  height: 82px;
  margin-right: 4px;
  background-color: #ececee;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
    position: relative;
  }
}
.showMoreImgStub {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(128, 128, 128, 0.4);
  color: white;
  font-size: 24px;
  text-align: center;
  line-height: 82px;
  z-index: 1;
  // transition: all 0.1s linear 0.2s;
  &:hover {
    font-size: 28px;
  }
}
.img {
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding: 2px;
  &:hover {
    padding: 0;
  }
}

.listItemFooter {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
}
.test11 {
  display: inline-block;
  vertical-align: middle;
}

.horizontalLine2 {
  border-bottom: 1px solid #e7e7e7;
  margin: 0 16px;
}

.loadMore {
  text-align: center;
  background-color: #6ac3fa3d;
  margin-bottom: 4px;
  padding: 4px 0;
  cursor: pointer;
  color: #656565;
}
