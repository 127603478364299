
  .notificationContainer {
    background-color: #ffffff;
    border-radius: 3px;
    width: 20vw;
    min-width: 380px;
    margin-right: 20px;
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    display: block;
    word-wrap: break-word;
    min-height: 200px;
    height: 40vh;
  }

  .filterButtonContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .flexContainer {
    display: flex;
  }

  .notificationTextHeader {
    font-weight: bold;
    font-size: 19px;
  }

  .horizontalLineContainer {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
