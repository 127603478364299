.helpIcon {
  display: inline-block;
  vertical-align: bottom;
  background-image: url('./icons8-info.png'); // 19 * 19px
  width: 16px;
  height: 16px;
  // margin-left: 8px;
  cursor: help;
  background-size: cover;
}
