
  .edit_private_data__container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    pointer-events: all;
    border-radius: 3px;
  }

  .edit_private_data__header {
    font-size: 19px;
    color: #333333;
  }

  .edit_private_data__text_input__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }

  .edit_private_data_span_label__container {
    margin-bottom: 10px;
  }

  .edit_private_data__span_label {
    font-size: 12px;
    color: #000000;
    opacity: 0.5;
  }

  .edit_private_data__password_container {
    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 3px;
    pointer-events: all;
  }
