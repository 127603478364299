
  .tabsContainer {
    margin-bottom: 20px;
  }

  .bodyContainer {
    padding: 5px;
    border: 1px solid #14306a;
    border-radius: 4px;
    &Invalid {
      border: 1px solid red;
    }
  }

  .buttonTabs {
    justify-content: flex-start;
    & > div {
      margin-right: 20px;
    }
  }
