
  .request_management__container {
    pointer-events: all;
    width: 13.9vw;
    min-width: 200px;
    height: 140px;
    background-color: #ffffff;
    top: -18px;
    left: 100px;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 20px;
  }

  .request_management__container_panel {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 14px;
    height: 100%;
  }

  .request_management__content {
    display: flex;
  }

  .request_management__content_icon {
    display: flex;
    align-items: center;
    height: 18px;
    width: 18px;
    justify-content: center;
  }

  .request_management__content_text {
    padding-left: 5px;
  }
