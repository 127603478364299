// from
// import styles from '../../SearchResultItem/SearchResultItemHeader.module.scss';

// .root {}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.label {
  // font-family: TT Hoves Regular, sans-serif;
  font-family: var(--main-regular-font);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  // padding-left: 10px;
  padding-left: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
}

.itemLabelContainer {
  position: relative;
  // cursor: pointer;
}
.itemLabel {
  display: inline-block;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: initial;
  white-space: nowrap; // for chrome!
}

// .itemLabelSubCopyId {

.itemHeadActions {
  display: flex;
  // align-items: center;
  align-items: flex-end;
}
.itemHeadActionButton {
  width: 32px; // 28px
  height: 32px; // 28px
  padding: 0;
}
// .cartButton {
//   &:hover,
//   &:global(.active) {
//     color: #5dd670;
//   }
// }
.removeButton {
  &:hover {
    color: #ff5f5f;
  }
}

// ------------
// .www, qqq {
//   margin-bottom: 20px;
// }
.conflicts {
  font-size: 14px;
  margin-left: 30px;
  margin-top: -4px;
  color: #b70b0b;
}
