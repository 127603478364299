.root {
  background-color: white;
  // padding: 28px 20px; 20px 16px
  margin-bottom: 8px;
  min-width: 330px;

  // max-height: calc(100vh - 300px);
  // overflow-y: auto;

  position: absolute;
  right: 0;
  z-index: 11;
  margin-top: 10px;
  // padding: 20px 0;
  border-radius: 3px;
}

// .header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 20px;

//   h3 {
//     margin: 0;
//     white-space: pre;
//   }
// }

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px;
}
.header2 {
  // h3
  margin: 0;
  white-space: pre;

  font-size: 1.125rem;
  line-height: 1.25rem;
  flex-grow: 1;
}

.stepLabel {
  font-size: 0.875rem; // 14px;
  // line-height: 1.5rem; // 24px;
  line-height: 2.375rem; // 38px;
  white-space: nowrap;
  color: #444;
  margin-left: 8px;
}

// .listHeader {
//   height: 43px;
//   padding: 0 20px;
//   line-height: 40px;
//   background-color: var(--main-primary-color);
//   color: white; // var(--text-secondary-color);

//   border-top-left-radius: 3px;
//   border-top-right-radius: 3px;
// }

// .list { }

.listItem {
  // background-color: white;
  // margin-bottom: 10px;
  // padding: 12px 16px;
  padding: 12px 20px 12px;

  user-select: none;
  // cursor: pointer;

  // &:hover {
  //   // background-color: lightblue; // lightgray
  //   // cursor: default;
  // }
  // temp test FF
  max-width: 310px;
}
.listItemSelected {
  background-color: rgba(167, 188, 253, 0.2);
}

.itemHeader {
  cursor: default;
  display: inline-flex; // flex;
  width: 100%;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  // word-break: break-all;
  white-space: nowrap;

  span {
    // white-space: pre-wrap;
    //
    // word-break: break-all;
    // fix for FF
    // word-wrap: break-word;
    // width: calc(100% - 40px); // - icon width
    //
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.itemBody {
  display: flex;
  justify-content: space-between;
  align-items: baseline; // center
}
.itemParams {
  font-size: 0.875rem;
  color: var(--main-secondary-active-color);

  min-width: 74px;
  text-align: right;
  margin-left: 10px;
}
.itemDivider {
  padding: 0 4px;
}

.priceValue {
  flex-grow: 1;
}
// .priceValue::after {
//   content: '\20bd';
//   color: var(--main-secondary-active-color);
//   padding-left: 4px;
// }
// croppingArea
.areaValue {
  font-size: 0.875rem; // 14px
  margin-left: 10px;
}

.itemHeadActionButton {
  width: 32px; // 28px
  height: 32px; // 28px
  padding: 0;
}

.previewData {
  // font-family: Verdana, Geneva, Tahoma, sans-serif;
  // font-size: 12px;
  // color: #8997b4;
  color: var(--text-secondary-color);
  font-size: 14px;

  // padding-left: 30px;
  margin-bottom: 4px;
}

.listItemFooter {
  display: flex;
  justify-content: space-between;
  // padding-left: 30px;
}
.test11 {
  display: inline-block;
  vertical-align: middle;
}

.horizontalLine2 {
  border-bottom: 1px solid #e7e7e7;
  margin: 0 16px;
}

.delimeter {
  width: 100%;
  padding: 8px 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
}

.outputProductParametersContainer {
  padding: 0 20px;
  min-width: 310px; // фикс для формы выходной продукции
}
