
  .searchRoot {
    top: -10px;
    right: 8px;
  }

  .searchInput {
    color: black;
    border-radius: 4px;
    outline: none;

    &:focus {
      border: 2px solid #a7b7e6;
    }

    // &::placeholder {
    //   color: black;
    // }
  }
