
  .notification_tab__container {
    padding: 20px;
    background-color: #ffffff;
    margin-top: 20px;
    border-radius: 3px;
  }

  .notification_tab__header_span {
    font-size: 12px;
    color: #8997b4;
  }

  .notification_tab__table_data_content {
    min-width: 120px;
    font-size: 12px;
  }
