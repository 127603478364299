// .qqq {
//   position: absolute;
//   left: 524px;
//   top: 113px;
//   pointer-events: all;
//   background-color: #fff;

//   width: 540px;

//   table {
//     width: 100%;
//   }
// }

.root {
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
}

.mainBlockContainer {
  padding: 20px 0 16px;
  background-color: #ffffff;
  border-radius: 3px;
  // margin-top: 20px;
  box-sizing: border-box;
  // pointer-events: none; // all
  pointer-events: all;

  max-height: 78vh; // 69vh;
  // min-width: 650px;
  // max-width: 50vw;

  min-width: 500px;
  max-width: 680px;

  // fix для autocomplete tags
  // overflow-y: auto;
  @media (max-height: 580px) {
    overflow-y: auto;
  }
}

.emptyMessage {
  min-width: 420px;
  padding: 4px 16px;
  margin-top: 16px;
  box-sizing: border-box;
  text-align: center;
}

.secondBlockContainer {
  // background-color: yellowgreen;
  margin-left: 16px;

  max-width: 28vw;
  min-width: 320px;
}
.secondBlockInner {
  // background-color: aquamarine;
  pointer-events: all;
  margin-bottom: 20px;
}

.titleContainer {
  display: flex;

  & > * {
    flex: 1;
  }
}

.title {
  margin: 0 20px 8px;
}

.table {
  width: 100%;
  margin-bottom: 10px;

  // hide fist col (id)
  // tr > th:nth-child(1),
  // tr > td:nth-child(1) {
  //   display: none;
  // }
  //

  tr {
    cursor: default;
  }

  tbody > tr {
    cursor: pointer;

    &:hover {
      background-color: #eeeef9;
      // border-bottom: 0;
    }
  }
  :global(tr.active) {
    background-color: #def1fc;
  }

  td,
  th {
    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
  }
  // :global(td.fakeTDataPadding) {
  //   width: 16px;
  //   padding: 0;
  //   border-bottom: 1px solid white;
  // }

  // .actionsTData {
  :global(td.noVPaddings) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

// .tRow {
//   cursor: pointer;
// }

// .dataCell {
// }
