.item {
  color: inherit;
  text-decoration: none;
  padding: 8px 12px;
  display: inherit;
}

.expanded_links {
  margin-left: 10px;
}

.expanded_links a:hover {
  background: grey;
}
.expanded_links span:hover {
  background: grey;
}
