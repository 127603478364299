
  .root {
    position: absolute;
    z-index: 20;
    bottom: 0;
    left: 0;
  }

  .image {
    vertical-align: middle;
    max-width: 70px;
  }
