// .div_search_option
.root {
  background-color: #14306a;
  border-radius: 3px;
  // width: 100%;
  padding: 20px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  margin-bottom: 8px;
  margin-right: 10px; // fix width
}

// .span_header_test {
.header {
  // font-family: TT Hoves Regular sans-serif;
  font-family: var(--main-demibold-font);
  font-size: 19px;
  color: #ffffff;

  margin-bottom: 8px;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.countImages {
  font-size: 1rem;
}

// table
.dataListItem {
  display: flex;
  justify-content: space-between;
  color: white;
  // color: #737373;
  // color: var(--text-secondary-color);
  font-size: 14px;
}
// cell
.dataTitle {
  margin-bottom: 4px;
}
.dataValue {
  margin-bottom: 4px;
  text-align: right;
}
// \end

.linkAction {
  // color: var(--text-secondary-color);
  color: var(--text-accent-color);
  cursor: pointer;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
}

.cross {
  margin: 0 0 0 4px; // 8px
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
