//.listItemBody {
.root {
  padding-left: 30px;
  user-select: text;
}

// table
.dataListItem {
  display: flex;
  justify-content: space-between;
  color: #737373;
  // color: var(--text-secondary-color);
  font-size: 14px;
}
// cell
.dataTitle {
  margin-bottom: 4px;
}
.dataValue {
  margin-bottom: 4px;
  margin-left: 20px;
  text-align: right;
}
// \end

.imgContainer {
  // width: 85px;
  width: 82px;
  height: 82px;
  margin-right: 10px; // 4px;
  background-color: #ececee;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
    position: relative;
  }
}
.showMoreImgStub {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(128, 128, 128, 0.4);
  color: white;
  font-size: 24px;
  text-align: center;
  line-height: 82px;
  z-index: 1;
  // transition: all 0.1s linear 0.2s;
  &:hover {
    font-size: 28px;
  }
}
.img {
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding: 2px;
  &:hover {
    padding: 0;
  }
}

.link {
  color: var(--text-accent-color);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.collapseBtn {
  font-size: 14px;
  // color: #636466;
  color: #5ab8f1;
  text-align: center;
  cursor: pointer;

  &:hover {
    // background-color: #14306a36;
    background-color: #a9c1f436;
  }
}
