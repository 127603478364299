
  // .root {}

  .dropdown li {
    padding: 0;
  }

  .dropdown a:hover {
    text-decoration: none;
  }

  .item {
    padding: 8px 12px;
    display: inherit; // list-item
  }

  .link {
    color: inherit;
    text-decoration: none;
  }
