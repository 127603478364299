.root {
  pointer-events: none;
  display: flex;
  flex-direction: row;
  // align-items: flex-start;
  // align-content: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

// .emptyWhiteBlock, .whiteBlock {
.emptyWhiteBlock {
  padding: 20px;
  border-radius: 3px;
  background-color: #ffffff;

  pointer-events: all;
  width: 420px;
  box-sizing: border-box;
}

.mainBlockContainer {
  // padding: 20px 0 16px;
  // background-color: #ffffff;

  border-radius: 3px;
  // margin-top: 20px;
  box-sizing: border-box;
  // pointer-events: none; // all
  // min-width: 650px;
  max-height: 69vh;
  // max-width: 50vw;
  overflow-y: auto;
  pointer-events: all;

  width: 420px;
}

.secondBlockContainer {
  // background-color: yellowgreen;
  margin-left: 16px;

  max-width: 28vw;
  min-width: 320px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;

    width: 420px;
    max-width: initial;
  }
}
.secondBlockInner {
  // background-color: aquamarine;
  pointer-events: all;
  margin-bottom: 20px;
}
