
  .root {
    background-color: white;
    // padding: 2px 8px;
    padding: 0 8px;
    border-radius: 3px;
  }
  .item {
    display: inline-block;
    vertical-align: middle;

    position: relative;
  }

  ///
  // https://medium.com/@dmitrynozhenko/5-ways-to-animate-a-reactjs-app-in-2019-56eb9af6e3bf
  //   https://reactjs.org/docs/animation.html#componentwillappear
  // https://github.com/arve0/react-animate-on-change
  //   https://developer.mozilla.org/ru/docs/Web/CSS/CSS_Animations/Ispolzovanie_CSS_animatciy#%D0%94%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%87%D0%B8%D0%BA%D0%B0_%D1%81%D0%BE%D0%B1%D1%8B%D1%82%D0%B8%D1%8F_%D0%B0%D0%BD%D0%B8%D0%BC%D0%B0%D1%86%D0%B8%D0%B8
  ///
