
  .root {
    display: flex;
    justify-content: space-between;
    // padding-bottom: '8px'; // !убирает скролл Y в FF
  }

  .descriptionContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 20px;
  }
  .descriptionItem {
    // font-family: TT Hoves Regular sans-serif;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 8px; /* 12px */
    height: 19px;
  }
