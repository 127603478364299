// @use '../../../../styles/helpers' as *; // <-- not work
@import 'src/styles/helpers';

// .root {
//   // margin-left: 16px;
//   margin-bottom: 20px;
// }

.infoPanel {
  /* padding: 20px;
  background-color: #14306a;
  border-radius: 3px;
  color: #ffffff; */
  // composes: className from "../../../../styles/helpers.module.scss";
  @include blue-box;

  min-width: 320px; // 400px
  // max-width: 28vw;
  box-sizing: border-box; // with padding
  // display: flex;
  // flex-direction: column;
}
.infoPanelHeader {
  // margin-top: 0;
  font-size: 1.125rem;
  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
}
.infoPanelHeaderActions {
  display: flex;
  position: relative;
  right: -10px;
  top: -4px;
}

.actionButton {
  padding: 0;
  width: 30px;
  height: 30px;
}

.infoPanelContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px; // 14px
  font-size: 0.875rem; // 14px
}

// .infoPanelContentLabel {}
.infoPanelContentValue {
  padding-left: 20px;
  text-align: right;
}

div.chipTag {
  background-color: white; // !important; --> use div.<class> selector
  color: #444;
  // padding: 2px 4px;
  // margin-left: 4px;
  // border-radius: 4px;
  // cursor: pointer;
  // user-select: none;
  margin-right: 0;

  &:hover {
    background-color: #ddd;
  }
}
