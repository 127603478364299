.root {
  position: absolute;
  right: 0;
  top: 0;
  /* margin-top: 140px; */
  margin-top: 65px;
  margin-right: 20px;
}

.body {
  background: white;
  border-radius: 4px;
  padding: 0 20px 20px;
  min-width: 200px;

  margin-bottom: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 10px 20px;
  padding-top: 10px;

  h3 {
    margin: 0;
    white-space: pre;
  }
}

/* .footer {} */

// table
.dataListItem {
  display: flex;
  justify-content: space-between;
  color: #737373;
  // color: var(--text-secondary-color);
  font-size: 14px;

  margin-bottom: 4px;
}
// cell
.dataTitle {
  // margin-bottom: 4px;
  margin-right: 18px;
}
/* .dataValue {
  // margin-bottom: 4px;
} */
// \end
