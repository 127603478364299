
  .checkbox {
    margin-bottom: 8px;
  }

  .bandColoredBox {
    display: inline-block;
    margin-right: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #b3b3b3;
  }
