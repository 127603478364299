// .root {
//
// }

.bottomCenter {
  position: absolute;
  bottom: 26px;
  display: flex;
  justify-content: center;

  width: 100%;
  // width: auto;
  pointer-events: none;
  flex-direction: column;
  z-index: 20;

  box-sizing: border-box;
  padding: 0 120px 0 440px;
}

.bottomRight {
  position: absolute;
  bottom: 90px;
  right: 20px;
  display: flex;
  justify-content: center;
  // width: 100%;
  pointer-events: none;
  flex-direction: column;
  z-index: 20;
}

.messageItemBottomCenter {
  align-self: center;

  // margin-left: calc((100vw - 450px) / 2); // calc((100vw - 450px) / 2 - 100px);
}

.messageItemBottomRight {
  align-self: flex-end;
}
