// .root {
//   position: absolute;
//   bottom: 26px;
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   pointer-events: none;
//   flex-direction: column;
// }

.container {
  background-color: #6b7275;
  color: #fff;
  opacity: 0.9;
  padding: 0.5em 1em;
  border-radius: 3px;
  z-index: 100;
  pointer-events: all;
  user-select: none;
  // align-self: center;
  margin-bottom: 0.25em;
  // display: inline-block;
  max-width: 40rem;
  // тень
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}

// .message {
//   padding: 0 10px;
// }

/* message bg colors */
.info {
  background-color: #6b7275;
  color: #fff;
}

.alert {
  background-color: #ff5552;
}

.warning {
  background-color: #ffd452;
  color: #2c363a;
}

.success {
  background-color: #41b849;
}
