.mapLeftSection {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 420px;
  min-width: 420px;
  margin: 8px 0 8px 20px;
}

.closePanelWrap {
  position: absolute;
  display: inline-block;
  line-height: 45px;
  margin-left: 10px;
  right: -30px;
  top: 0px;
}

.closePanelBtn {
  background-color: #4d4d4d;
  color: #fff;
  padding: 12px 4px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;

  font-size: 7px;
  vertical-align: top;

  &:hover {
    background-color: grey;
  }

  svg {
    transform: rotate(-90deg);
  }
}
.closePanelBtnShow {
  svg {
    transform: rotate(90deg);
  }
}

.mapLogo {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 310px 0 480px;
  text-align: center;
}

.mapMainGroup {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px 20px 0 0;
  display: flex;
  align-items: flex-start;
}

.marginLeft {
  margin-left: 5px;
}
