.dropdown li {
  padding: 0;
}

.dropdown a:hover {
  text-decoration: none;
}

.item {
  padding: 8px 12px;
  display: inherit;
}

.link {
  color: inherit;
  text-decoration: none;
}

.modalContent {
  padding: 20px;
  max-width: 550px;
}

.expand_item {
  display: flex;
  flex: row;
  justify-content: flex-start;
  align-items: center;
}

.expand_item span:last-of-type {
  margin-left: auto;
  margin-right: 10px;
}
