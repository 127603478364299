.root {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  border-radius: 8px; // 3px;
  border: 1px grey solid;
  background-color: white;
  width: 40%;
  min-width: 400px;
  padding: 20px;
}
.containerSuccess {
  border: 1px #1d9719 solid;
}
.containerFail {
  border: 1px red solid;
}

.headTitle {
  font-size: 1.5rem;
  text-align: center;
}

// .descriptionText {}

// https://snipp.ru/html-css/dl-dt-dd
.orderInfo {
  dt {
    float: left;
    clear: both;
    // width: 40%;
    // min-width: 150px;
    width: 150px;
  }

  dd {
    margin-left: 150px;
  }
}

.link {
  color: #187cb9;
}

.delimeter {
  height: 1px;
  background-color: grey;
  // margin: 0 10px;
}

.footer {
  text-align: center;
  font-size: 1.125rem;
}
