.dateRangeContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dateInput {
  width: 49%;
}

.dateRangeSet {
  cursor: pointer;
  font-size: 14px; // 12px
  color: var(--text-accent-color); // --main-secondary-color

  span {
    padding-right: 16px;
    &:hover {
      text-decoration: underline;
    }
  }
}
