
  .request_info_panel__container {
    display: flex;
    flex-direction: column;
    color: #ffffff;
  }
  .request_info_panel__header {
    margin-top: 0;
    font-size: 19px;
  }
  .request_info_panel__content {
    display: flex;
    justify-content: space-between;
    padding-top: 14px;
    font-size: 12px;
  }
