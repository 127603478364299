.root {
  background-color: white;
  border-radius: 3px;
}

.actionButton {
  width: 40px;
  height: 40px;
  border: 0;
}
