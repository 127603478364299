.check_box__container {
  display: block;
  position: relative;
  // padding-left: 35px;
  // margin-bottom: 8px; /* 12px */
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  width: 22px;
  height: 22px;
}

.check_box__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

// .check_box__label {
//   font-style: normal;
//   font-size: 14px;
//   color: var(--text-primary-color);
// }

.check_box__check_mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid var(--main-check-box-mark-color);
  border-radius: 2px;
}

.check_box__container:hover .check_box__input:enabled ~ .check_box__check_mark {
  background-color: var(--main-check-box-mark-color);
}

.check_box__container:hover .check_box__input:disabled ~ .check_box__check_mark {
  cursor: default;
}

.check_box__check_mark:after {
  content: '';
  position: absolute;
  display: none;
}

.check_box__container .check_box__input:checked ~ .check_box__check_mark:after {
  display: block;
}

.check_box__container .check_box__input:disabled ~ .check_box__check_mark {
  background-color: var(--checkbox-disabled-color);
  border: 1px solid var(--checkbox-disabled-color);
}

.check_box__container .check_box__check_mark:after {
  left: 7px;
  top: -1px;
  width: 5px;
  height: 17px;
  border: solid var(--main-secondary-color);
  border-width: 0 2px 2px 0;
  transform: rotate(33deg);
}

.check_box__container .indeterminate:after {
  display: block;

  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  background-color: var(--main-secondary-color);

  border: 0;
  transform: none;
}
