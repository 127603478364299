.root {
  margin-top: 4px;
}

// .delimeter {
//   width: 100%;
//   padding: 8px 0;
// }

// accordion
.paramsHeader {
  // font-size: 14px;
  font-weight: bold;
  font-size: 1rem; // 16px
  line-height: 1rem;

  width: 100%;
  text-align: center;
}

.paramTitle {
  margin: 4px 0 10px;
  font-size: 0.875rem; // 14px
}

.paramField {
  margin-bottom: 12px;
}

.paramContainer {
  display: flex;
  justify-content: space-between;
  // paddingTop: '10px' /*'38px'*/,
}

.width_50 {
  // width: 120px;
  width: 49%;
}
