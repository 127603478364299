
  // .root {}
  // .menu {
  //   width: 'auto';
  // }

  .itemContainer {
    display: flex;
    // padding: 4px 0;
    // padding: 5px 0;
  }
  .itemLabel {
    padding-left: 10px;
  }
