
  .root {
  }

  .emptyMessage {
    padding: 12px 10px; /* 12px 20px */
    // width: 200px;
  }

  .itemRoot {
    display: flex;
    justify-content: space-between;
  }
  .itemHead {
    flex-grow: 1;
  }
  .itemHeadTitle {
    font-size: smaller;
    color: gray;
  }
  .itemActions {
    display: flex;
    justify-content: flex-end;
  }
