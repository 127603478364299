.listContainer {
  width: 300px;
  font-size: 0.875rem;

  li:hover {
    background-color: rgba(197, 197, 197, 0.14);
    cursor: default;
  }
}

.emptyText {
  padding: 16px 20px;
}

.counterWrap {
  background-color: #5ab8f1; // red;
  width: 16px;
  height: 16px;
  // border: 1px solid white;
  border-radius: 8px; // 9px

  position: absolute;
  top: 4px;
  right: 4px;
  text-align: center;
  // cursor: pointer;
  user-select: none;
}
.counterValue {
  color: white;
  font-size: 8px;
  line-height: 16px; // 17px
  vertical-align: top;
}
