body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --map-vertical-margin: 8px; /* top */
  --map-horizontal-margin: 10px; /* left, right */
  --checkbox-disabled-color: rgba(128, 128, 128, 0.2);
}

a {
  text-decoration: none;
  color: var(--text-accent-color);
}
a:hover {
  text-decoration: underline;
}

/* react-tooltip-lite */
.react-tooltip-lite {
  background: #333;
  color: white;
  font-size: 0.875rem;
  /* padding: 4px 8px; */
  border-radius: 4px; /* 3px */
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

.tip-content--border-grey .react-tooltip-lite {
  border: 1px solid rgba(0, 0, 0, 0.18);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.tip-content--infoproduct .react-tooltip-lite {
  max-width: 370px !important;
}

.tip-content--instrumentdescription .react-tooltip-lite {
  max-width: 270px !important;
}

/* .tip-content--searchheaderactions .react-tooltip-lite {
  max-width: 250px !important;
} */
.tip-content--width-150 .react-tooltip-lite {
  width: 150px !important;
}

.tip-content--width-280 .react-tooltip-lite {
  width: 280px !important;
}

.tip-content--width-320 .react-tooltip-lite {
  width: 320px !important;
}

.tip-no-padding .react-tooltip-lite {
  padding: 0 !important;
}
