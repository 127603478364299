
  .root {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 999;
  }

  .tabContainer {
    margin: 0 24px;
    cursor: pointer;
    color: #5dbdf9;
    height: 43px;
    display: flex;
    align-items: center;
  }

  .tabContainerActive {
    margin: 0 24px;
    cursor: pointer;
    color: #5dbdf9;
    height: 43px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #5dbdf9;
  }
