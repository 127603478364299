
  .request_info_panel__component__container {
    padding: 20px;
    background-color: #14306a;
    border-radius: 3px;
    min-width: 400px;
  }

  .request_info_panel__component__close_button {
    display: flex;
    pointer-events: all;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    background-color: white;
    border-radius: 3px;
  }

  .my_request {
    display: flex;
  }

  .my_request__container {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 3px;
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
    pointer-events: all;
    min-width: 650px;
    max-height: 69vh;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 65vh;
  }

  .my_requests__table_header__span {
    font-size: 12px;
    color: #8997b4;
  }
