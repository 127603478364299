
  .root {
    // overflow-x: hidden;
    // overflow-y: auto;
    // height: 144px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    /* justify-content: space-around; */
    & > div > ol {
      width: 100%;
      color: white;
      font-size: 14px;
      line-height: 20px;
      user-select: none;
    }
  }
