.counterWrap {
  background-color: red;
  width: 16px;
  height: 16px;
  // border: 1px solid white;
  border-radius: 8px; // 9px

  position: absolute;
  top: 6px;
  right: 2px; // 4px
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.counterValue {
  color: white;
  font-size: 8px;
  line-height: 16px; // 17px
  vertical-align: top;
}
