.previewData {
  // font-family: Verdana, Geneva, Tahoma, sans-serif;
  // font-size: 12px;
  // color: #8997b4;
  color: var(--text-secondary-color);
  font-size: 14px;

  padding-left: 30px;

  user-select: text;
}

// .linkAction
.linkButton {
  // display: flex;
  // justify-content: flex-start;
  color: var(--text-accent-color);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
