.ol-popup {
  position: absolute;
  /* width: 220px; */
  max-height: calc(100vh - 260px);
  background: #b3d4f3a3;
  overflow-y: auto; /* scroll */
  left: 470px;
  top: 45px;
  z-index: 5;
  /* padding: 10px; */
}

/* override */
/* override */
.ol-scale-line {
  /* background: rgba(0, 60, 136, 0.3); */
  /* border-radius: 4px; */
  /* bottom: 8px; */
  right: 210px;
  left: auto;
  padding: 2px;
  position: absolute;

  /* new */
  bottom: 0;
  background-color: rgba(23, 24, 67, 0.4);
  border-radius: 4px 4px 0 0;
  padding: 3px 4px;
}

.ol-scale-line-inner {
  color: #eee;
  border: 1px solid #eee;
  border-top: none;
}

.ol-zoom {
  top: 50%;
  left: auto;
  /* right: 10px; */
  right: var(--map-horizontal-margin);
}
.ol-zoom > button {
  background-color: white;
  color: #5ab8f1;
  width: 40px;
  height: 40px;
  font-size: 24px;
}
.ol-zoom > button:focus:hover,
.ol-zoom > button:hover {
  /* background-color: rgba(128, 128, 128, 0.1); */
  background-color: rgb(244, 244, 244);
}
.ol-zoom > button:focus {
  /* background: inherit; */
  /* background-color: initial; */
  background-color: white;
}

/* .ol-attribution {
  text-align: right;
  bottom: 0.5em;
  right: auto;
  left: 0.5em;
  max-width: calc(100% - 1.3em);
} */
.ol-attribution {
  text-align: right;
  /* bottom: 0.5em; */
  bottom: 0;
  right: auto;
  left: 70px;
  /* left: 0.5em; */
  /* left: var(--map-horizontal-margin); */
  max-width: calc(100% - 1.3em);
  padding: 0;
  background-color: rgba(23, 24, 67, 0.4);
  color: white;
  font-size: 14px;
  border-radius: 4px 4px 0 0;
}
.ol-attribution:hover {
  background-color: rgba(23, 24, 67, 0.4);
}
.ol-attribution:not(.ol-collapsed) {
  background-color: rgba(23, 24, 67, 0.4);
}
.ol-attribution ul {
  color: inherit;
  text-shadow: none;
}
.ol-attribution button {
  color: inherit;
  height: 22px;
  width: 22px;
  margin: 0;
  padding: 0;
  color: #eee;
  background: transparent;
  cursor: pointer;
}
.ol-attribution button:focus,
.ol-attribution button:hover {
  background: transparent;
  color: white;
}

.ol-mouse-position {
  top: auto;
  right: 8px;
  position: absolute;
  /* bottom: 8px; */
  /*font-size: 0.9375em;*/ /* 15px */

  /* new */
  bottom: 0;
  font-size: 12px;
  line-height: 18px;
  /* background-color: rgba(23, 24, 67, 0.5); */
  background-color: rgba(23, 24, 67, 0.4);
  /* background: rgba(0, 60, 136, 0.3);
  color: #eee; */
  padding: 2px 6px; /* 0 8px */
  /* color: white; */
  color: #eee;
  border-radius: 4px 4px 0 0;
}

.ol-zoom-level {
  top: auto;
  left: 45%;
  position: absolute;
  /* bottom: 8px; */
  /*font-size: 0.9375em;*/ /* 15px */

  /* new */
  bottom: 0;
  font-size: 12px;
  font-weight: bold;
  /* line-height: 18px;   */
  height: 20px;
  width: 300px;
  background-color: rgba(23, 24, 67, 0.4);
  color: #eee;
  border-radius: 4px 4px 0 0;
}

.ol-zoom-level-text {
  margin-top: 3px;
  margin-left: 5px;
  margin-right: 5px;
}

.ol-brightness-temperature {
  position: absolute;
  right: 390px;
  left: auto;
  /* top: auto;
  left: 70%; */
  /* bottom: 8px; */
  /*font-size: 0.9375em;*/ /* 15px */

  /* new */
  bottom: 0;
  font-size: 12px;
  font-weight: bold;
  /* line-height: 18px;   */
  height: 22px;
  /* width: 240px; */
  background-color: #ffffff;
  color: #ffffff;
  border-radius: 4px 4px 0 0;
  margin-top: 3px;
  margin-left: 5px;
  margin-right: 5px;
  object-fit: contain;
}
/* end */
