// .finishedProductsContainer {
.root {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background-color: #ffffff;

  min-width: 320px; // 400px
  // max-width: 28vw;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  background-color: #14306a;
  padding: 20px;
  border-radius: 3px 3px 0 0;
  align-items: center;
}

.headerLabel {
  color: #ffffff;
  font-size: 1.125rem;
}

.loaderContainer {
  width: 100%;
  height: 2px;
  background-color: rgba(20, 48, 106, 0.1);
  margin: -2px 0 0;
}

.progressText {
  font-size: 0.875rem;
  text-align: right;
  padding: 4px 10px 0;
}

.downloadAllActionLabel {
  color: var(--main-secondary-color);
  font-size: 0.875rem;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
}

// .headerActions {
//   display: flex;
//   align-items: center;
//   margin-left: 10px;
// }

// .actionLink {
//   padding-top: 20px;
//   cursor: pointer;
//   color: #5dbdf9;

//   &:hover {
//     color: #58a8e1;
//   }
//   &:active {
//     color: #539ed2;
//   }
// }

.bodyContainer {
  padding: 16px 10px 20px 20px;
}

.productItem {
  margin-right: 10px;
}

// ---

.productContainer {
  margin-bottom: 10px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.productTitle {
  // margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 4px;
}

// .productPreview {
//   width: 24px;
//   height: 24px;
//   margin-right: 10px;
//   color: var(--icon-primary-color); // #2954ff;
//   background-color: #f0f6ff;
//   text-align: center;
//   border-radius: 3px;
//   cursor: pointer;
//   display: inline-block;

//   &:hover {
//     background-color: #d7e4f7; // #e9f2ff;
//   }

//   svg {
//     vertical-align: middle;
//   }
// }

.productList {
  padding-left: 16px;
}

.removeInfo {
  font-size: 0.875rem;
  margin-left: 8px;
}

.buttonsGroup {
  display: flex;
  justify-content: flex-end;
}

.mapActionButton {
  width: 32px;
  height: 32px;
  padding: 0;

  margin-top: 4px;
  margin-left: 4px;

  &:global(.active) {
    color: #14306a;
    background-color: #ceeafb;

    &:hover {
      background-color: #f1f1f1;
    }
  }
}
