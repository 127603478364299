
  .root {
    background-color: #14306a;
    width: 100%;
    height: 62px;
    position: absolute;
    top: 0;

    display: flex;
    justify-content: space-between;
  }

  .mapLeftSection {
    // position: absolute;
    z-index: 20;
    // top: 0;
    // left: 0;
    width: 420px;
    margin: 8px 0 8px 20px;
  }

  .mapMainGroup {
    // position: absolute;
    // top: 0;
    // right: 0;
    margin: 10px 20px 0 0;
    display: flex;
    align-items: flex-start;
  }

  .marginLeft {
    margin-left: 5px;
  }
