.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fileName {
  font-size: 0.875rem; // 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
}

.actions {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

// .actionBtn {
//   cursor: pointer;
// }
.itemHeadActionButton {
  width: 28px; // 32px
  height: 28px; // 32px
  padding: 0;

  &:hover {
    color: var(--icon-primary-color);
  }
}

.loaderContainer {
  width: 100%;
  height: 2px; // 3px
  background-color: rgba(20, 48, 106, 0.1);
  // margin: 10px 0 10px 0;
  margin: 6px 0; // 8px 0
}
