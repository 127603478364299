// .root {}

.titleContainer {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 4px;
}

.title {
  color: grey;
  font-style: italic;
}

.date {
  color: grey;
  font-size: 0.8125rem;
}

.messageContainer {
  display: flex;
  justify-content: space-between;
}

// .message {
//   margin-right: 4px;
// }

// .action {
// }
