
  .finishedProductsContainer {
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    background-color: #ffffff;
  }

  .finishedProductsHeader {
    display: flex;
    justify-content: space-between;
    background-color: #14306a;
    padding: 20px;
    border-radius: 3px 3px 0 0;
    align-items: center;
  }

  .finishedProductsHeaderSpan {
    color: #ffffff;
    font-size: 19px;
  }

  .loaderContainer {
    width: 100%;
    height: 3px;
    background-color: rgba(20, 48, 106, 0.1);
    margin: 10px 0 10px 0;
  }

  .spanClick {
    padding-top: 20px;
    cursor: pointer;
    color: #5dbdf9;
    &:hover {
      color: #58a8e1;
    }
    &:active {
      color: #539ed2;
    }
  }
