.settingsContainer {
  margin-left: 5px;
  margin-bottom: 5px;
}

.settingsTitle {
  color: #606060;
  margin: 5px;
}

.itemTitle {
  color: #606060;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.content {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  margin-left: 15px;
  margin-bottom: 10px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.95em;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 7px;
  margin-left: 5px;
}
