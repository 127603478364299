// .root {
//   margin-top: 4px;
// }

.drawRoot {
  position: absolute;
  /* left: 430px; // move style to parent */
  left: 10px;
}
.drawInfoContainer {
  position: absolute;
  top: 0;
  // left: calc(100vw / 2 - 300px);
  left: calc((100vw - 460px) / 2 - 150px);
}
.drawInfoTitle {
  white-space: nowrap;
  background-color: white;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
}
.drawInfoActions {
  display: flex;
  justify-content: space-between;
  margin: 0 18px;
}

// root container
.root {
  display: flex;
  flex-direction: column;
  min-width: 360px;
  max-width: 370px;
  background-color: #ffffff;
  padding: 20px;
  padding-bottom: 0;
  border-radius: 3px;
  box-sizing: border-box;
  // position: absolute;
  /* left: 430px; // move style to parent */
  // bottom: 8px; // если прижимать к низу

  // временно сделал стандартный скролл
  overflow-y: auto;
  // max-height: 100%; // не всегда подходит. когда в результатах поиска 1-2 снимка - высота этого (правого блока) усекается по высоте блока с рез-ми
  max-height: calc(100vh - 94px);
}

.delimeter {
  width: 100%;
  margin: 8px 0;
  height: 1px;
  background-color: rgba(128, 128, 128, 0.4);
}

.body2 {
  padding: 0 10px 0 20px;
  margin-bottom: 10px;
}

.header {
  // font-size: 1.25rem; // 20px
  font-size: 1.125rem; // 18px
  // text-transform: uppercase;
  font-family: var(--main-demibold-font);
}
.header2 {
  // h3
  // font-size: 1.25rem; // 20px
  font-size: 1.125rem; // 18px
  line-height: 1.25rem; // 20px
  font-family: var(--main-demibold-font);
  margin: 0;
}

.stepLabel {
  font-size: 0.875rem; // 14px;
  line-height: 1.5rem; // 24px;
  white-space: nowrap;
  color: #444;
  margin-left: 8px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  // align-items: baseline;
  align-items: flex-start;
}

// accordion
.paramsHeader {
  // font-size: 14px;
  font-weight: bold;
  font-size: 1rem; // 16px
  line-height: 1rem;

  width: 100%;
  text-align: center;
}

.paramTitle {
  margin: 4px 0 10px;
  // font-size: 0.875rem; // 14px
  font-size: 1rem;
}

.paramField {
  margin-bottom: 12px;
}

.paramContainer {
  display: flex;
  justify-content: space-between;
  // paddingTop: '10px' /*'38px'*/,
}

.priceContainer {
  font-size: 1.125rem; // 18px

  span {
    color: #b70b0b;
  }
}

// buttons, actions
.actionsContainer {
  display: flex;
  justify-content: space-around;

  padding-bottom: 20px;
}

.marginBottom {
  // padding-top: 20px;
  margin-bottom: 16px; // 1rem
}

.width_50 {
  // width: 120px;
  width: 49%;
}

// subList
.marginLeft {
  margin-left: 30px;
}
